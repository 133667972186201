import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import read from '@mc/page-data';
import ErrorBoundary from '@mc/bugsnag';
import ErrorScreen from '@mc/components/ErrorScreen';
import App from './App';

const pageData = read('js-page-data');

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <App {...pageData} />
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

// You might be thinking "I should abstract this into @mc/mount or in to a helper function."
// Great idea! However this will break hot loading because webpack relies on this existing at the entry point during compile time.
if (__DEV__ && !__REACT_REFRESH__ && module.hot) {
  module.hot.accept();
}
