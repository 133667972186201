import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import log, { CLASSIFICATION } from '@mc/es-logger';
import { getTimezoneLabel, isValidTimeZone } from '@mc/dates';

const ROLES = {
  owner: 5,
  admin: 4,
  manager: 3,
  author: 2,
  viewer: 1,
};

const UserContext = React.createContext(null);

class UserProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const hasRole = (role) => {
      if (__DEV__) {
        if (!(role in ROLES)) {
          throw new Error(
            `"${role}" is an invalid user role. ` +
              `We expected one of the following: ${Object.keys(ROLES)}`,
          );
        }
      }

      return ROLES[props.user.role] >= ROLES[role];
    };

    const hasFeature = (feature) => {
      return !!(
        props.user.policy && Object.keys(props.user.policy).includes(feature)
      );
    };

    const setUserPolicy = (policy) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ policy });
    };

    const setHasAcceptedContentGenTerms = (hasAcceptedContentGenTerms) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ hasAcceptedContentGenTerms });
    };

    /**
     * Sets the value of dismissedFreeTrialUpgradeReminder in the genAiTrialInfo state object.
     * Only updates the value if the PLAN_GENAI_ADDON_EXPERIMENT flag is enabled.
     *
     * @param {boolean} hasDismissed - The new value for dismissedFreeTrialUpgradeReminder.
     */
    const setHasDismissedFreeTrialReminder = (hasDismissed) => {
      this.setState((prevState) => {
        return {
          genAiTrialInfo: {
            ...prevState.genAiTrialInfo,
            dismissedFreeTrialUpgradeReminder: hasDismissed,
          },
        };
      });
    };

    const setPromptToAddPaymentMethod = (shouldPromptToAddPaymentMethod) => {
      this.setState((prevState) => {
        return {
          billing: {
            ...prevState.billing,
            promptToAddPaymentMethod: shouldPromptToAddPaymentMethod,
          },
        };
      });
    };

    /**
     * This is to show a global success message on checkout.
     * @param {bool} value this turns success message on/off
     */
    const setPromotionMessaging = (value) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ promotionMessaging: value });
    };
    const dismissNotification = (id) => {
      this.setState((prevState) => {
        return {
          notifications: {
            items: prevState.notifications.items.filter(
              ({ id: notificationId }) => {
                return notificationId !== id;
              },
            ),
            count: prevState.notifications.count - 1,
          },
        };
      });
    };

    const dismissAllNotifications = () => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        notifications: {
          items: [],
          count: 0,
        },
      });
    };

    const setUserPaginationPageSize = (paginationPageSize) => {
      this.setState({ paginationPageSize });
    };

    const user = { ...props.user };

    const isAcpEligible =
      props.user.hasPremiumMonthlyPlan ||
      (props.user.hasLegacyPaidPlan && props.user.isPro) ||
      props.user.hasStandardMonthlyPlan;
    user.isAcpEligible = isAcpEligible;

    user.requiresApprovalForLau = props.user.requiresApprovalForLau;

    // this code isn't working on Windows 10 + Chrome for some users
    // I don't know if I can use flags in User.js - something to investigate.
    if (props.user && props.user.timezoneId) {
      // set the user timezoneLabel from Intl api
      const hasValidTimezone = isValidTimeZone(props.user.timezoneId);
      // if ( invalid timezone - log it)
      if (!hasValidTimezone) {
        log({
          channelName: 'timezone-incorrect-date-display',
          classification: CLASSIFICATION.SENSITIVE,
          message:
            'User timezone.id is not valid for Intl.DateTimeFormat (user.js es6)',
          data: {
            timezoneId: props.user.timezoneId,
          },
        });
      }

      const timezoneLabel = hasValidTimezone
        ? getTimezoneLabel(props.user.timezoneId)
        : props.user.timezoneLabel;
      user.timezoneLabel = timezoneLabel;
    }

    this.state = {
      ...user,
      // eslint-disable-next-line react/no-unused-state
      setBillingRenewalState: this.setBillingRenewalState,
      // eslint-disable-next-line react/no-unused-state
      setPausedState: this.setPausedState,
      // eslint-disable-next-line react/no-unused-state
      dismissAllNotifications,
      // eslint-disable-next-line react/no-unused-state
      dismissNotification,
      // eslint-disable-next-line react/no-unused-state
      hasRole,
      // eslint-disable-next-line react/no-unused-state
      hasFeature,
      // eslint-disable-next-line react/no-unused-state
      setPromptToAddPaymentMethod,
      // eslint-disable-next-line react/no-unused-state
      setPromotionMessaging,
      // eslint-disable-next-line react/no-unused-state
      setUserPolicy,
      // eslint-disable-next-line react/no-unused-state
      setHasAcceptedContentGenTerms,
      // eslint-disable-next-line react/no-unused-state
      promotionMessaging: null,
      setUserPaginationPageSize,
      setHasDismissedFreeTrialReminder,
    };
  }

  /**
   * Allow paused state to be set in-app.
   * @param {bool} value user is paused or is not paused
   */
  setPausedState = (value) => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      isPaused: value,
    });
  };

  /**
   * Allow renewal state to be set in-app.
   * @param {bool} value user is in a failed billing renewal state
   */
  setBillingRenewalState = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        billing: {
          ...prevState.billing,
          isRenewalFailed: value,
        },
      };
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

/**
 * @typedef UserData
 * @property {string} accountName
 * @property {string} avatarUrl
 * @property {Object} billing
 * @property {boolean} billing.isRenewalFailed
 * @property {boolean} billing.isInPaymentGracePeriod
 * @property {boolean} billing.promptToAddPaymentMethod
 * @property {boolean} billing.nextBillingDate
 * @property {boolean} canSend
 * @property {string} email
 * @property {string} contactEmail
 * @property {string} phone
 * @property {string} firstName
 * @property {string} lastName
 * @property {boolean} hasPremiumMonthlyPlan
 * @property {boolean} hasStandardMonthlyPlan
 * @property {boolean} hasEssentialsMonthlyPlan
 * @property {boolean} hasZendeskTalk
 * @property {number} id
 * @property {boolean} isPro
 * @property {boolean} isPaused
 * @property {boolean} isUserLessThanThirtyOneDaysOld
 * @property {function(boolean)} setBillingRenewalState
 * @property {function(boolean)} setPausedState
 * @property {number} loginId
 * @property {Object} notifications
 * @property {number} notifications.count
 * @property {Array} notifications.items
 * @property {(string|boolean)} openComplianceIssue
 * @property {string} role
 * @property {string} langPreference
 * @property {string} languageName
 * @property {Array} genAIAllowedLanguages
 * @property {string} langPage
 * @property {string} countryCode
 * @property {string} timezoneLabel
 * @property {string} timezoneId
 * @property {string} timezoneOffset
 * @property {string} type
 * @property {function(string): boolean} hasFeature
 * @property {function(string): boolean} hasRole
 * @property {function(): void} dismissAllNotifications
 * @property {function(number): void} dismissNotification
 * @property {boolean} showCheckoutConfirmation
 * @property {boolean} hasLegacyPaidPlan
 * @property {boolean} isFree
 * @property {int} planAudienceSize
 * @property {boolean} hasPartnerCenterAccess
 * @property {boolean} isMcAdminUser
 * @property {boolean} hasNeaSite
 * @property {boolean} hasMonthlyPlan
 * @property {boolean} hasLegacyPlan
 * @property {boolean} isCommercePlanFree
 * @property {boolean} isPayGo
 * @property {boolean} isNonProfit
 * @property {string} whyNotSend
 * @property {string} whyNotSendTitle
 * @property {string} startOfWeek
 * @property {boolean} hasProvisionalAccount
 * @property {boolean} hasQboAccountAndLoginLink
 * @property {string} industry
 * @property {string} businessName
 * @property {boolean} isAcpEligible
 * @property {boolean} hasAdvancedSegmentation
 * @property {string} datePatternPreference
 * @property {boolean} hasProducts
 * @property {boolean} canAccessContentGen
 * @property {boolean} hasAcceptedContentGenTerms
 * @property {boolean} isBilledMonthly
 * @property {boolean} isUserInSmsSupportedCountry
 * @property {Object} smsPlan
 * @property {boolean} requiresApprovalForLau
 * @property {Array} contentGenEcommData
 * @property {string} intuitQboRealmId
 * @property {boolean} hasACPEligiblePlan
 * @property {int} paginationPageSize
 * @property {object} genAiTrialInfo
 */

/**
 * Returns a context-provided instance of user data.
 *
 * @return {UserData} A user data object
 */
function useUserData() {
  return useContext(UserContext);
}

export { UserProvider, useUserData };
