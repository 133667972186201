import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

/** DESIGN SYSTEM UPDATE
 * TO-DO: This should be moved over to @mc/wink
 */
const Portal = ({
  children,
  className = 'portal-root',
  mountNode,
  portalStack,
  prependNode = false,
}) => {
  const node = useRef(null);
  if (!node.current) {
    node.current = document.createElement('div');
    node.current.className = className;
    node.current.dataset.portalStack = portalStack;
  }

  useLayoutEffect(() => {
    const mountElement = mountNode ? mountNode() : document.body;

    if (prependNode) {
      mountElement.insertBefore(node.current, mountElement.firstChild);
    } else {
      mountElement.appendChild(node.current);
    }

    return () => {
      mountElement.removeChild(node.current);
      node.current = null;
    };
  }, [mountNode, prependNode]);

  return ReactDOM.createPortal(children, node.current);
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mountNode: PropTypes.func,
  portalStack: PropTypes.string,
  prependNode: PropTypes.bool,
};

export default Portal;
